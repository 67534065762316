import { useTranslation } from 'react-i18next';
import {
  Accordion,
  Button,
  Flex,
  List,
  NumberInput,
  Text,
} from '@mantine/core';
import { MySlider } from '../../../../../../component';
import { useState } from 'react';
import { observer } from 'mobx-react';
import { useConsumptionStore } from '../../stores';
import { defaultTHCLimitInGrams } from '@web/common';

export const ChangeTHCLimit = observer(() => {
  const { t } = useTranslation('consumption-dialog');
  const store = useConsumptionStore();
  const options = t('ChangeTHCLimit.Options', {
    returnObjects: true,
  }) as string[];

  const [manualInput, setManualInput] = useState(false);

  function toggleManualInput() {
    setManualInput(!manualInput);
  }

  function numberValid(value: number) {
    if (value > defaultTHCLimitInGrams) return false;

    // returns true when number is .0 or .5
    return value % 0.5 === 0;
  }

  return (
    <Flex direction="column" gap="10px">
      <Flex
        w="100%"
        bg="#F7F6F1"
        p="20px"
        ta="center"
        direction="column"
        justify="center"
        align="center"
        style={{
          border: '1px solid #D0CDB1',
          borderRadius: 5,
        }}
      >
        <Text size="16px" lh="24px" fw="600">
          {t('ChangeTHCLimit.Card.Title')}
        </Text>
        <Text>{t('ChangeTHCLimit.Card.SubTitle')}</Text>
        {manualInput ? (
          <NumberInput
            py="19.5px"
            label="Maximale THC Menge"
            w="200px"
            min={0}
            max={10}
            error={
              !numberValid(store.newLimit) &&
              'Der Wert muss kleiner als 10 sein und auf .0 oder .5 enden.'
            }
            decimalScale={1}
            fixedDecimalScale
            value={store.newLimit.toPrecision(2)}
            onChange={(v) => store.setNewLimit(Number(v))}
            rightSection={
              <Text
                size="16px"
                lh="18px"
                p="8px"
                c="#4A4A48"
                style={{ borderLeft: '1px solid #C7CED5' }}
              >
                g THC
              </Text>
            }
            rightSectionWidth="70px"
          />
        ) : (
          <Text
            size="48px"
            lh="60px"
            fw="600"
            c={store.newLimit > 0 ? '#000' : '#B1B1B1'}
            py="20px"
          >
            {store.newLimit.toPrecision(2)}g
          </Text>
        )}
        <MySlider
          setValue={(val) => store.setNewLimit(val / 10)}
          value={store.newLimit * 10}
        />
        <Button variant="transparent" onClick={toggleManualInput}>
          {t(
            'ChangeTHCLimit.Card.' +
              (!manualInput ? 'ManualInput' : 'SliderInput'),
          )}
        </Button>
      </Flex>
      <Accordion defaultValue="default">
        <Accordion.Item key="default" value="default">
          <Accordion.Control>
            <Text size="16px" lh="24px" fw="600">
              {t('ChangeTHCLimit.Title')}
            </Text>
          </Accordion.Control>
          <Accordion.Panel>
            <Text size="16px" lh="24px">
              {t('ChangeTHCLimit.Description')}
            </Text>
            <br />
            <Text>{t('ChangeTHCLimit.OptionTitle')}</Text>
            <List type="ordered" py="0px">
              {options.map((option) => (
                <List.Item key={option}>
                  <Text key={option} size="16px" lh="24px">
                    {option}
                  </Text>
                </List.Item>
              ))}
            </List>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>

      <Text size="14px" lh="21px" c="#4A4A48">
        {t('ChangeTHCLimit.PleaseCheck')}
      </Text>
    </Flex>
  );
});
