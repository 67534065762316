import { setupMobX } from '../../../../../util/setupMobX';
import { observable, makeObservable, action, runInAction } from 'mobx';
import {
  defaultTHCLimitInGrams,
  ErrorCode,
  type ThcConsumptionStatisticsResDto,
  ThcLimitReqDto,
} from '@web/common';
import { showErrorNotification } from '../../../../../component/notification';
import { handleRequestError } from '../../../../../request';
import {
  getMyCurrentThc,
  getCurrentThcLimit,
  getMyThcStatistics,
  setMyThcLimit,
  doesLimitNeedToBeSet,
} from '../../../../../request/authenticated-requests/participant-thc';

export class ConsumptionStore {
  @observable public loadingStatistics: boolean = false;
  @observable public loadingCurrentLimit: boolean = false;
  @observable public newLimit: number = 0;
  @observable public currentLimit: number = 0;
  @observable public currentThcAmount: number = 0;
  @observable public currentThcUnit: string = 'g';
  @observable public statistics: ThcConsumptionStatisticsResDto | undefined =
    undefined;
  @observable public hasToSetTHCLimit: boolean = false;

  constructor() {
    makeObservable(this);
  }

  @action public getCurrentThcAmount = () => {
    getMyCurrentThc()
      .then((response) => {
        runInAction(() => {
          this.currentThcAmount = response.thcConsumptionThisMonth;
          this.currentThcUnit = response.unit;
        });
      })
      .catch((error) => handleRequestError(error));
  };

  @action public getCurrentLimit = () => {
    this.loadingCurrentLimit = true;

    getCurrentThcLimit()
      .then((res) => runInAction(() => (this.currentLimit = res.thcLimit)))
      .catch(() => showErrorNotification())
      .finally(() => runInAction(() => (this.loadingCurrentLimit = false)));
  };

  @action public setNewLimit = (newLimit: number) => {
    // this caps the selected amount to the current maximum limit
    if (newLimit > defaultTHCLimitInGrams) newLimit = defaultTHCLimitInGrams;

    this.newLimit = newLimit;
  };

  @action public getStatistics = () => {
    this.loadingStatistics = true;
    getMyThcStatistics()
      .then((res) => runInAction(() => (this.statistics = res)))
      .catch(() => showErrorNotification())
      .finally(() => runInAction(() => (this.loadingStatistics = false)));
  };

  @action public updateCurrentThc = (onSubmit: () => void) => {
    const data = {
      thcLimitInGrams: this.newLimit,
    } as ThcLimitReqDto;

    setMyThcLimit(data)
      .then(() => {
        runInAction(() => {
          onSubmit();
          this.getCurrentLimit();
          this.getIsThcLimitUpdateRequired();
        });
      })
      .catch((error) =>
        handleRequestError(error, [
          ErrorCode.CannavigiaAPIError,
          () => {
            showErrorNotification(ErrorCode.CannavigiaAPIError);
          },
        ]),
      );
  };

  @action public getIsThcLimitUpdateRequired = () => {
    doesLimitNeedToBeSet()
      .then((res) =>
        runInAction(() => (this.hasToSetTHCLimit = res.hasToSetTHCLimit)),
      )
      .catch(() => showErrorNotification());
  };
}

const { provider, useStore } = setupMobX<ConsumptionStore>();
export const useConsumptionStore = useStore;
export const ConsumptionStoreProvider = provider;
